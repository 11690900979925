DocumentFolder.prototype = new Folder();

function DocumentFolder() {
    Folder.apply(this, arguments);
    //Folder.call(this);
    this.type = "documents";
}



DocumentFolder.prototype.constructor = DocumentFolder;

DocumentFolder.prototype.displayFolder = function(response) {
        $("#folder_content").html("");        

        if (!response.isRoot) {
            // Display parent
            $("#folder_content").append(this.makeParentDiv(response.parent));
        }
        // Display Subfolders
        var subdirs = response.directories;
        for (var i = 0; i < subdirs.length; ++i) {
            var dir = subdirs[i];
            $("#folder_content").append(this.makeFolderDiv(dir));
        }
        var docs = response.files;
        for (var i = 0; i < docs.length; ++i) {
            var doc = docs[i];
            $("#folder_content").append(this.makeDocumentDiv(doc));
        }
        this.initDragAndDrop();
    };

DocumentFolder.prototype.moveDocument = function(id, dest, callback) {
        $.ajax({
            url: "admin/documents/api/move",
            method: "POST",
            data: {
                "destination": dest,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });

    };

DocumentFolder.prototype.modify = function(id, name, usepw, pw, callback) {
        $.ajax({
            url: "admin/documents/api/modify",
            method: "POST",
            data: {
                "name": name,
                "setpassword": usepw,
                "password": pw,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

DocumentFolder.prototype.deleteDocument = function(id, callback) {
        $.ajax({
            url: "admin/documents/api/delete",
            method: "POST",
            data: {
                "id": id
            },
            success: function success(response) {
                callback(true);
            },
            error: function error() {
                callback(false);
            }
        });
    };

DocumentFolder.prototype.multiDelete = function(elements, callback) {
        $.ajax({
            url: "admin/documents/api/multidelete",
            method: "POST",
            data: {
                "elements": elements
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

DocumentFolder.prototype.multiMove = function(elements, destination, callback) {
        $.ajax({
            url: "admin/documents/api/multimove",
            method: "POST",
            data: {
                "elements": elements,
                "destination": destination
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

DocumentFolder.prototype.multiSelect = function() {
        this.selectMultiples = true;
        $("#admin_documents_select_multiple").hide();
        $("#admin_documents_select_single").show();
        // $("#admin_documents_actions").show();
    };

DocumentFolder.prototype.singleSelect = function() {
        this.selectMultiples = false;
        $("#admin_documents_select_multiple").show();
        $("#admin_documents_select_single").hide();
        // $("#admin_documents_actions").hide();
        // $(".document_selected").removeClass("document_selected");
    }

DocumentFolder.prototype.makeFolderDiv = function(folder) {
        return `
            <div class="document_folder document_element" id="folder_${folder.id}" type="folder" folder="${folder.id}" title="${folder.name}">
                <div class="document_draggable">
                    <div class="document_draggable_cursor">
                        <i class="material-icons">drag_handle</i>
                    </div>
                </div>
                <div class="document_content">
                    <i class="material-icons folder-icon blue-text text-darken-2">folder</i>
                    <div class="document_folder_name" id="folder_name_${folder.id}">${folder.name}</div>
                </div>
            </div>
        `;
    };

DocumentFolder.prototype.makeParentDiv = function(id) {        
        return `
            <div class="document_folder document_parent" type="parent" folder="${id}">
                <div class="document_content">
                    <i class="material-icons folder-icon amber-text text-darken-2">folder</i>
                    Übergeordnetes Verzeichnis
                </div>
            </div>
        `;
    };

DocumentFolder.prototype.makeDocumentDiv = function(doc) {        
        return `
            <div class="document_document document_element" id="document_${doc.id}" type="document" document="${doc.id}" 
                title="${doc.name}" t="${doc.thumbnail}" l="${doc.large}" m="${doc.medium}"
                mime="${doc.mime}" haspw="${doc.haspw ? 'true' : 'false'}">
                <div class="document_draggable">
                    <div class="document_draggable_cursor">
                        <i class="material-icons">drag_handle</i>
                    </div>
                </div>
                <div class="document_content">
                    <img src="${doc.thumbnail}" /><br />
                    <span id="document_name_${doc.id}">${doc.name}</span>
                </div>
            </div>
        `;
    };

DocumentFolder.prototype.animateOut = function (selector) {
        $(selector).fadeTo("fast", 0, function () {
            $(selector).animate({
                width: 0,
                height: 0
            }, "fast", function () {
                $(selector).remove();
            });
        });
    };

DocumentFolder.prototype.getSelected = function() {
        var selected = {
            documents: new Array(),
            folders: new Array()
        }
        $(".document_selected").each(function(index) {
            var type = $(this).attr("type");
            if (type == "folder") {
                selected.folders.push($(this).attr("folder"));
            } else if (type == "document") {
                selected.documents.push($(this).attr("document"));
            }
        });
        return selected;
    };

DocumentFolder.prototype.initDragAndDrop = function() {
        var that = this;
        $(".document_document").draggable({
            containment: ".maincontent",
            stack: ".document_element",
            handle: ".document_draggable",
            revert: true
        });
        $(".document_folder:not(.document_parent)").draggable({
            containment: ".maincontent",
            stack: ".document_element",
            handle: ".document_draggable",
            revert: true
        });
        $(".document_folder").droppable({
            accept: ".document_element",
            hoverClass: "document_folder_drop_hover",
            drop: that.dropHandler
        });
    };

DocumentFolder.prototype.dropHandler = function(event, ui) {
        var dest = $(this).attr("folder");
        // Freeze Element
        ui.draggable.draggable("disable");
        ui.draggable.draggable("option", "revert", false);
        
        var src_type = ui.draggable.attr("type");
        // Move Multiple Elements
        var selected = documentFolder.getSelected();
        if (!ui.draggable.hasClass("document_selected")) {
            ui.draggable.addClass("document_selected");
            if (src_type == "folder") {
                var src_id = ui.draggable.attr("folder");
                selected.folders.push(src_id);
            } else if (src_type == "document") {
                var src_id = ui.draggable.attr("document");
                selected.documents.push(src_id);
            }
        }          
        documentFolder.multiMove(selected, dest, function(result) {
            if (result.errorcount == 0) {
                documentFolder.animateOut(".document_selected");
            } else {
                var notify = new Notification();
                notify.warning(`Nicht alle Elemente konnten verschoben werden: 
                               ${result.errorcount} Fehler`);
                for (var i = 0; i < result.moved.documents.length; ++i) {
                    id = result.moved.documents[i];
                    documentFolder.animateOut("#document_"+id);
                }
                for (var i = 0; i < result.moved.folders.length; ++i) {
                    id = result.moved.folders[i];
                    documentFolder.animateOut("#folder_"+id);
                }
            }
        });
    };

DocumentFolder.prototype.deleteSelected = function(selected, countstr) {
    var dlg = new Dialog();    
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie die ausgewählten Elemente wirklich unwiderruflich löschen?<br />
        Sie haben ${countstr} ausgewählt.`,
        "Ja",
        "Nein",
        function(result) {
            if (result) {
                dlg.block();
                documentFolder.multiDelete(selected, function(result) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (!result) {
                        notify.error("Beim löschen ist ein Fehler aufgetreten");
                    } else {
                        dlg.close();
                        if (result.errorcount == 0) {
                            notify.success("Löschen erfolgreich");
                            documentFolder.animateOut(".document_selected");
                        } else {
                            notify.warning(`Nicht alle Elemente konnten gelöscht werden: 
                                           ${result.errorcount} Fehler`);
                            for (var i = 0; i < result.deleted.documents.length; ++i) {
                                id = result.deleted.documents[i];
                                documentFolder.animateOut("#document_"+id);
                            }
                            for (var i = 0; i < result.deleted.folders.length; ++i) {
                                id = result.deleted.folders[i];
                                documentFolder.animateOut("#folder_"+id);
                            }
                        }
                    }
                });
            } else {
                dlg.close();
            }
        }
    );        
}

DocumentFolder.prototype.addDeleteListener = function() {
    $(document).on("keydown.folder_content", function(e) {
        if (e.which == 46) {
            if (!$(e.target).is("body.mainbody") || Dialog.isOpened()) {
                return;
            }
            var selected = documentFolder.getSelected();
            var count = selected.folders.length + selected.documents.length;
            var countstr = count == 1 ? `ein Element` : `${count} Elemente`;
            if (count > 0) {
                documentFolder.deleteSelected(selected, countstr);
            }
        }
    });
}


var documentFolder = new DocumentFolder();

$("body").on("click", "#admin_documents_folder_create", function() {
    var dlg = new Dialog();
    dlg.input("Ordner erstellen",
        "Wählen Sie einen Namen für den Ordner",
        "Namen angeben:",
        "",
        "Neuer Ordner",
        "Ordner erstellen",
        "Abbrechen",
        function(res) {
            if (res === false) {
                dlg.close();
                return;
            }
            if (res.length == 0) {
                var notify = new Notification();
                notify.info("Der Titel darf nicht leer sein");
            } else {
                dlg.block();
                documentFolder.createFolder(res, function(result) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (result === false) {
                        notify.error("Ordner konnte nicht erstellt werden");
                    } else {
                        notify.success("Ordner wurde erstellt");
                        dlg.close();
                        documentFolder.update();
                    }
                });
            }
        });
});

// DOCUMENT UPLOAD
$("body").on("click", "#admin_documents_upload", function() {
    var dlg = new Dialog();
    dlg.dropzone(
        "Dokumente hochladen",
        "Wählen Sie ein oder mehrere Dokumente zum hochladen aus oder ziehen Sie sie in den Upload-Bereich",
        "Fertig",
        "admin/documents/api/upload",
        Array("parent", documentFolder.id),
        function(result) {
            dlg.close();
            var notify = new Notification();
            notify.info("Upload beendet");
            documentFolder.update();
        }
    );
});

// Multi Select
$("body").on("click", "#admin_documents_select_multiple", function() {
    documentFolder.multiSelect();
});

// Single Select
$("body").on("click", "#admin_documents_select_single", function() {
    documentFolder.singleSelect();
});

// Delete Selected
$("body").on("click", "#admin_documents_delete_selected", function() {
    var selected = documentFolder.getSelected();
    var count = selected.folders.length + selected.documents.length;
    var actions = new Dialog();

    if (count == 0) {
        actions.alert("Keine Elemente", "Sie haben keine Elemente ausgewählt", "Ok");
        return;
    }
    var countstr = count == 1 ? `ein Element` : `${count} Elemente`;

    var cancel = function() { actions.close(); };

    var dlg = new Dialog();
    dlg.confirm(
        "Wirklisch löschen?",
        `Wollen Sie ${countstr} wirklich löschen?`,
        "Ja",
        "Nein",
        function(result) {
            if (result) {
                dlg.block();
                documentFolder.multiDelete(selected,function(res) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (res) {
                        notify.success(`${countstr} gelöscht`);
                        dlg.close();
                        documentFolder.update();
                    } else {
                        dlg.error("Beim Löschen ist ein Fehler aufgetreten");
                    }
                });
            } else {
                dlg.close();
            }
        }
    );
});

// DOCUMENT Click: MODIFY
$("body").on("click", ".document_document", function(e) {
    var target = $(e.target);
    if (target.is("div.document_draggable") || target.parents("div.document_draggable").length) {
        return;
    }

    if (documentFolder.selectMultiples) {
        $(this).toggleClass("document_selected");
    } else {
        var medium = $(this).attr("m");
        var name = $(this).attr("title");
        var haspw = $(this).attr("haspw") == "true";
        var id = $(this).attr("document");
        var dlg = new Dialog();
        var close = function() {dlg.close()};
        var submit = function() {
            dlg.block();
            var dlgid = dlg.getId();
            var newname = $("#dialog_"+dlgid+"_input_document_name_"+id).val();
            var setpw = $("#document_setpw").prop("checked");
            var password = $("#document_password").val();

            if (newname == "") {
                var notify = new Notification();
                notify.info("Der Name darf nicht leer sein");
                dlg.unblock();
            } else {
                documentFolder.modify(id, newname, setpw, password, function(result) {
                    dlg.unblock();
                    if (!result) {
                        var notify = new Notification();
                        notify.error("Änderungen konnten nicht gespeichert werden");
                    } else {
                        var notify = new Notification();
                        notify.success("Änderungen wurden gespeichert");
                        var thumbnail = $("#document_"+id).attr("t");
                        dlg.close();
                        $("#document_name_"+id).text(newname);
                        haspw = setpw && (haspw || password != "");
                        $("#document_"+id).attr("haspw", haspw);
                    }
                });
            }
        };        
        var deleteDoc = function() {
            dlg.hide();
            var deleteConfirm = new Dialog();
            deleteConfirm.confirm(
                "Wirklich löschen?", 
                `Wollen Sie das Dokument "${name}" wirklich löschen?`,
                "Ja",
                "Nein",
                function(result) {
                    if (result) {
                        deleteConfirm.block();
                        documentFolder.deleteDocument(id, function(result) {
                            deleteConfirm.unblock();
                            if (result) {
                                var notify = new Notification();
                                notify.success("Dokument wurde gelöscht");
                                deleteConfirm.close();
                                dlg.unblock();
                                dlg.close();
                                documentFolder.animateOut("#document_"+id);
                            } else {
                                var notify = new Notification();
                                notify.error("Dokument konnte nicht gelöscht werden");
                            }
                        });
                    } else {
                        deleteConfirm.close();
                        dlg.reshow();
                    }
                }
            );
        };
        dlg.build()
            .addTitle("Dokument verwalten")
            .addHtml(`<div style="text-align: center"><img align="center" class="dialog_document" src="${medium}" /></div><br />`)
            .addButton("Speichern", "save", submit)
            .addButton("Abbrechen", "cancel", close)
            .addDeleteButton("Löschen", "delete", deleteDoc)
            .onEscape(close)
            .onEnter(submit)
            .addInput("Name", name, "Name des Dokumentes", "document_name_" + id, submit)
            .addHtml(`
                <h3>Passwort Einstellungen</h3>
                <input class="checkbox_large" type="checkbox" id="document_setpw" />
                <label for="document_setpw" class="checkbox_label">Dokument durch Passwort schützen</label>
                <br />
                <label class="dialog_input_label" for="document_password">Passwort angeben (Nur zum Ändern ausfüllen)</label><br />
                <input type="text" id="document_password" placeholder="Nur zum Ändern ausfüllen" class="dialog_input" />
            `)
            .show()
            .focus("document_name_"+id);

        $("#document_setpw").prop("checked", haspw);
        
    }
});

// FOLDER Click
$("body").on("click", ".document_folder", function(e) {
    var target = $(e.target);
    if (target.is("div.document_draggable") || target.parents("div.document_draggable").length) {
        return;
    }

    if (documentFolder.selectMultiples) {
        if ($(this).attr("type") == "folder") {
            $(this).toggleClass("document_selected");
        } else {
            var notify = new Notification();
            notify.info(`Sie befinden sich im Auswahlmodus.<br />
                         Das übergeordnete Verzeichnis kann nicht ausgewählt werden.`);
        }
    } else {
        var id = $(this).attr("folder");
        if (target.is("div.document_folder_name") && $(this).attr("type") == "folder") {
            var dlg = new Dialog();
            dlg.input(
                "Ordner umbenennen", 
                "Benennen Sie den Ordner um",
                "Namen eingeben",
                $(this).attr("title"),
                "Ordnername",
                "Ok",
                "Abbrechen",
                function(newname) {
                    if (!newname) {
                        dlg.close();
                    } else {
                        if (newname == "") {
                            var notify = new Notification();
                            notify.info("Der Name darf nicht leer sein");
                        } else {
                            dlg.block();
                            documentFolder.renameFolder(id, newname, function(result) {
                                dlg.unblock();
                                var notify = new Notification();
                                if (!result) {
                                    notify.error("Ordner konnte nicht umbenannt werden");
                                } else {
                                    notify.success("Ordner umbenannt");
                                    $("#folder_name_"+id).text(newname);
                                    $("#folder_"+id).attr("title", newname);
                                    dlg.close();
                                }
                            });
                        }
                    }
                }
            );
        } else {
            documentFolder.navigate($(this).attr("folder"));                    
        }
    }
});

// Document Contextmenu
$("body").on("contextmenu", ".document_document", function(e) {
    e.preventDefault();
    $(this).toggleClass("document_selected");
});

// Folder Contextmenu
$("body").on("contextmenu", ".document_folder", function(e) {
    e.preventDefault();
    if ($(this).attr("type") == "folder") {
        $(this).toggleClass("document_selected");
    }
});

$("body").on("click", ".documents_help", function() {
    var dlg = new Dialog();

    var close = function() {
        dlg.close();
    };

    dlg.build()
        .addTitle("Dokumente: Hilfe")
        .addHtml(`
            <h4>Dokumente hochladen</h4>
            Verwenden Sie den Button "Dokumente hochladen", um neue Dokumente in den aktuellen
            Ordner zu laden. Sie können mehrere Dokumente gleichzeitig hochladen.

            <h4>Dokument verwalten</h4>
            Klicken Sie auf ein Dokument, um es umzubenennen, mit einem Passwort zu versehen oder zu löschen.<br />
            Sie haben die Möglichkeit, Dokumente mit einem Passwort zu versehen. Hierdurch kann es nur von Nutzern
            heruntergeladen werden, welche über dieses Passwort verfügen.

            <h4>Ordner erstellen</h4>
            Verwenden Sie den Button "Ordner erstellen", um einen neuen Ordner zu erstellen.
            Das Sortieren / Verschieben von Dokumenten und Ordnern hat keinen Einfluss auf die
            Galeriezuweisung.

            <h4>Ordner umbenennen</h4>
            Um einen Ordner umzubenennen, klicken Sie auf den Ordnernamen.

            <h4>Verschieben von Elementen</h4>
            Ziehen Sie ein Dokument oder einen Ordner auf einen Ordner, um es zu verschieben.
            Haben Sie mehrere Elemente markiert, werden alle markierten Elemente verschoben.

            <h4>Markieren von Elementen</h4>
            Verwenden Sie den Rechtsklick (langes Drücken auf mobilen Geräten), um ein Element 
            zu markieren. Alternativ können Sie in den Auswahlmodus wechseln, um Elemente per 
            Linksklick zu markieren.

            <h4>Aktionen für markierte Elemente</h4>
            Haben Sie ein oder mehrere Elemente ausgewählt, können Sie diese über den Button "Aktionen"
            löschen oder einer Galerie hinzufügen.<br />
            Zum Löschen markierter Elemente können Sie auch die Entfernen Taste drücken.
        `)
        .addButton("Ok", "ok", close)
        .onEscape(close)
        .onEnter(close)
        .show();
});